
import { mdiMagnify } from '@mdi/js'
import axios from 'axios'

export default {
  data: () => ({
    mdiMagnify,
    blogsListPreview: [],
    showBlogs: false,
    spinner: true,
    searchDescription: '',
    filteredBlogsBySearch: [],
    filteredBlogsBySearchTags: [],
    queryParams: null,
    category: '',
    filteredBlogs: [],
  }),
  async fetch() {
    for (let i = 0; ; i++) {
      const options = {
        method: 'GET',
        url: `https://kaiden-api-stage.azurewebsites.net/Blog/blog-posts-website-preview-individually/${i}`,
      }
      try {
        const response = await axios.request(options)

        this.showBlogs = true

        if (response.status === 204) {
          this.spinner = false
          break
        }

        this.blogsListPreview.push(response.data)

        await this.searchDescriptionItems()
      } catch (error) {
        console.error(error)
      }
    }
  },
  fetchOnServer: false,

  head() {
    return {
      title: 'Blog',
      titleTemplate: '%s | Great Canadian Training',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Read on for actionable tips and valuable insights on how to make the most of your professional development!',
        },
      ],

    }
  },
  computed: {
    webPage() {
      return `Blog Landing Page`
    },
    filteredBlogsByCategory() {
      return this.blogsListPreview.filter((x) =>
        x.categories.includes(this.category)
      )
    },
  },
  beforeMount() {
    this.queryParams = this.$route.query
  },
  mounted() {
    this.getBlogCategory()
    this.searchDescriptionItems()
  },
  methods: {
    searchDescriptionItems() {
      this.filteredBlogsBySearch = this.blogsListPreview
        .filter((x) => x.categories.includes(this.category))
        .filter((d) =>
          d.title.toLowerCase().includes(this.searchDescription.toLowerCase())
        )
    },
    searchDescriptionItemsByTag() {
      this.filteredBlogsBySearchTags = this.blogsListPreview
        .filter((x) => x.categories.includes(this.category))
        .filter(
          (d) =>
            d.tags
              .toLowerCase()
              .includes(this.searchDescription.toLowerCase()) &&
            !d.title
              .toLowerCase()
              .includes(this.searchDescription.toLowerCase())
        )
    },
    selectCategory(cat, $event) {
      this.category = cat
      const inactiveButtons = document.querySelectorAll('.category-button')
      inactiveButtons.forEach((element) => {
        element.classList.remove('button-active')
      })

      $event.target.closest('button').classList.add('button-active')
    },
    getBlogCategory() {
      if (this.$route.fullPath.includes('?')) {
        const catParam = Object.values(this.queryParams)[0]

        if (isNaN(Object.values(this.queryParams)[0])) {
          const inactiveButtons = document.querySelectorAll('.category-button')

          inactiveButtons.forEach((element) => {
            element.classList.remove('button-active')
          })

          inactiveButtons.forEach((element) => {
            if (element.textContent.replace(/\s+/g, ' ').includes(catParam)) {
              element.classList.add('button-active')
            }
          })

          this.category = catParam
        }
      }
    },
  },
}
