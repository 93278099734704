
export default {
  props: ['blogsListPreview', 'search'],
  data() {
    return {
      openingParagraph: '',
    }
  },
  methods: {
    highlightWord(text) {
      if (this.search !== '') {
        return text.replace(
          new RegExp(this.search, 'gi'),
          (str) =>
            `<span style="background-color: #f28a04; color: white; padding: 1px 2px;">${str}</span>`
        )
      } else {
        return text
      }
    },
    getHTMLContent(text) {
      const div = document.createElement('div')
      div.innerHTML = text

      return div.innerText
    },
  },
}
